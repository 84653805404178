#header {
  padding-top: 36px;
  padding-bottom: 36px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100vw;
  background-color: #ffffff;
}
#header-height.header-height {
  height: 112px;
}
#header .logo {
  width: 50px;
  margin-left: 30px;
  cursor: pointer;
}
@media (min-width: 990px) {
  #header .logo {
    width: 71px;
    margin-left: min(12.4vw, 180px);
  }
}

#header .navbar-list {
  margin-right: min(158px, 10vw);
  margin-top: 6px;
}
#header .navbar-list a {
  font-size: min(20px, 3vw);
  padding-bottom: 2px;
  margin-left: min(2vw, 83rem);
  margin-right: min(2vw, 83rem);
  font-family: "Roboto flex";
  font-weight: 300;
  cursor: pointer;
  color: var(--bs-main);
}

#header .navbar-list a.active {
  font-weight: 700;
  border-bottom: 3px var(--bs-main) solid;
}
#header .navbar-list a:hover {
  font-weight: 400;
}
#header .menu-toggle {
  margin-right: 30px;
}
