#honk section.hero {
  background-color: white;
  width: 100vw;
  height: 118.5vw;
  position: relative;
  z-index: -1;
}
@media (min-width: 576px) {
  #honk section.hero {
    background-color: white;
    height: 51.5vw;
    position: relative;
  }
}

#honk section.hero img.img1 {
  width: 100%;
  top: 0%;
}

#honk section.prototype {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#honk section.prototype p {
  margin: 2vw auto 0;
  font-size: 16px;
  width: 83vw;
  line-height: 1.45;
}
#honk section.prototype button {
  background-color: #414346;
  color: white;
  font-weight: 700;
  font-size: 15px;
  border: none;
  margin-top: 20.4vw;
  padding: 15px 38px;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 576px) {
  #honk section.prototype p {
    margin: 1vw auto 0;
    font-size: 18px;
    width: 50vw;
    line-height: 1.45;
  }
  #honk section.prototype button {
    font-size: 16px;
    margin-top: 1vw;
    padding: 10px 60px;
    border-radius: 8px;
  }
}
#honk section.prototype button:hover {
  transform: scale(1.05);
  filter: brightness(0.9);
}
#honk section.prototype .summary img {
  margin-left: auto;
  margin-right: auto;
  max-width: 646px;
  width: 100%;
  padding-top: 0px;
  /* padding-bottom: 40px; */
}
@media (min-width: 576px) {
  #honk section.prototype .summary img {
    padding-top: 80px;
    /* padding-bottom: 40px; */
  }
}
